<template>
  <v-menu offset-y close-on-content-click>
    <template v-slot:activator="{ on, attrs }">
      <v-btn outlined class="ma-2" v-bind="attrs" v-on="on" :disabled="disabled">
        <v-icon left> mdi-clipboard-text-search-outline</v-icon>
        Text-Vorlagen
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in availableTextTemplates"
        :key="index"
        @click="templateSelected(item)"
      >
        <v-list-item-title>{{ item.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import TextTemplateMixin from "@/mixins/TextTemplateMixin";

export default {
  name: "TextTemplateSelector",
  mixins: [TextTemplateMixin],
  props:{
    disabled: {
      type: Boolean,
      required: false,
      default: () => false
    },
  },
  computed: {
    availableTextTemplates() {
      return this.textTemplateItems;
    },
  },
  methods: {
    templateSelected(textTemplate) {
      if (textTemplate) {
        this.$emit("textTemplateSelected", textTemplate.content)
      }
    },
  },
}
</script>
