import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";

export default {
  data() {
    return {
      loadingTextTemplates: [],
    };
  },
  computed: {
    ...mapFields("textTemplate", {
      textTemplateIds: "allIds",
      textTemplateIdsCached: "cacheAllIds",
    }),
    ...mapGetters("textTemplate", {
      textTemplateItems: 'list',
      findTextTemplate: "find",
    }),
  },
  methods: {
    ...mapActions("textTemplate", {
      retrieveTextTemplate: "load",
      fetchAllTextTemplates: 'fetchAll',
    }),
    resolveTextTemplate(itemId) {
      if (!this.textTemplateIdsCached.includes(itemId) && !this.loadingTextTemplates.includes(itemId)) {
        this.loadingTextTemplates.push(itemId);
        this.retrieveTextTemplate(decodeURIComponent(itemId));
      }
      return this.findTextTemplate(itemId);
    },
    resolveTextTemplates(itemIds) {
      if (typeof itemIds === "undefined") {
        return [];
      }
      return itemIds.map((itemId) => {
        return this.resolveTextTemplate(itemId);
      }).filter(e => e);
    },
    getTextTemplateItem(textTemplateIri, type) {
      if (textTemplateIri !== null) {
        let textTemplate = this.resolveTextTemplate(textTemplateIri);
        if (textTemplate) {
          return textTemplate[type];
        }
      }
      return '';
    },
  }
};
